import React, { useState ,useRef} from "react";
import initializeFirebase from '../../../firebaseConfig';

import { increment, push, ref, update } from "firebase/database";
import { getCurrentUTCTimestamp } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import GiphySearchbox from 'react-giphy-searchbox';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Gif from '@mui/icons-material/Gif';
import CloseIcon from '@mui/icons-material/Close';
import { commentGifUploadThumbnail, commentImageUploadThumbnail } from "../service";
import Translate from "../../../Components/MultiLanguage/Translate";
import { useTranslateText } from "../../../Components/MultiLanguage/Translate";

const ReplyField = ({setIsReplyField,childReplyFieldHandler,type,data,className , projectInfo}) => {

  const firebaseDB = initializeFirebase(projectInfo);
  const user = useSelector((state) => state?.user?.value);
  const userDetails = useSelector((state) => state?.userDetails?.value);  
  const [replyCommentValue,setReplyCommentValue] = useState("")
  const [selectedGifReply, setSelectedGifReply] = useState(null);
  const [imageReply, setImageReply] = useState(null);
  const [imagePreviewReply, setImagePreviewReply] = useState(null);
  const [imageDimensionsReply, setImageDimensionsReply] = useState({ width: 0, height: 0 });
  const [isGiphyModalOpenReply, setIsGiphyModalOpenReply] = useState(false);
  const appInfo = {
    projectDetails: projectInfo,
  };
  const fileInputRefReply = useRef(null); 
  const translateText = useTranslateText();
  const gifApiKey = projectInfo?.projectConfig?.config?.GIPHY_API_KEY
  const onGifSelectReply = async (item) => {
    setImageReply(null); // Ensure only one of image or GIF is selected
    setImagePreviewReply(null);
    setIsGiphyModalOpenReply(false); // Close modal after selection
    const gifUrl = item.images?.fixed_width_small?.url;
    const response = await  commentGifUploadThumbnail(gifUrl,appInfo)
    if(response?.data?.data){
      setImageDimensionsReply({
        height:parseInt(item.images?.fixed_width_small?.height),
        width:parseInt(item.images?.fixed_width_small?.width),
        url:response.data.data
      })
    
  
        setSelectedGifReply(item);
    }
       
       
      
         
    setSelectedGifReply(item);
  };

  const handleFileChangeReply = async (e) => {
    let replyimageData =[]
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (!allowedExtensions.exec(e?.target?.files[0]?.name)) {
      // alert(<Translate textKey={'invalid_extension_message'}/>);
    } else {
      const files = e?.target?.files;
      setSelectedGifReply(null); // Ensure only one of image or GIF is selected
      const response = await commentImageUploadThumbnail(files[0],appInfo)
      if(response?.data?.data){
      const reader = new FileReader();
      reader.onloadend = () => {
        const imgReply = new window.Image();
        imgReply.onload = () => {
          setImageDimensionsReply({ width: parseInt(imgReply.width), height: parseInt(imgReply.height),
            url: response.data.data
           })
        };
        imgReply.src = reader.result;
        setImagePreviewReply(reader.result); // Set preview URL
      };
      setImageReply(files[0]);
      reader.readAsDataURL(files[0]);
    }
    if (fileInputRefReply.current) {
      fileInputRefReply.current.value = null;
  }
    }
    
  };
    const replyCommentHandler = async () => {
      if (replyCommentValue || selectedGifReply || imageReply) {
    
    
 
        let reply_comment_data = {
          comment_text: replyCommentValue || '',
          user_id: Number(user),
          created_at: getCurrentUTCTimestamp(),
        }
        if(imageDimensionsReply != null){
          reply_comment_data.image = imageDimensionsReply
        }
        const firebaseDB = initializeFirebase(projectInfo);
        const dbRefReplies = ref(firebaseDB,`comments/${data?.videoId}/${data?.id}`)
        update(dbRefReplies, {
          reply_count: increment(1),
        });
          push(ref(firebaseDB, `comments/${data?.videoId}/${data?.id}/replies`),reply_comment_data ).then(()=>{
            setSelectedGifReply(null);
            setImageReply(null);
            setImagePreviewReply(null);
                setIsReplyField(false)

          }
           )
      }else{
        toast.error(<Translate textKey={'reply_field_required'}/>,{
          position:"top-center"
        })
      }
    }

    const cancelReplyHandler = () => {
      setIsReplyField(false)
      childReplyFieldHandler(false)
      setSelectedGifReply(null);
      setImageReply(null);
      setImagePreviewReply(null);
          setIsReplyField(false)
    }

    const handleGiphyButtonClickReply = () => {
      console.log('GIF button clicked');
      setIsGiphyModalOpenReply(true);
    };
    const handleRemoveSelectionReply = () => {
      setSelectedGifReply(null);
      setImageReply(null);
      setImagePreviewReply(null);
    };
  return (
    <div className={`replyField ${className}`}>
      <div className="left">
        <div className="imageContainer">
          
        {
              userDetails?.user_image?(
                <img src={userDetails?.user_image} alt="Profile" />
              ):(
                <span className="nameAvatar">{userDetails?.first_name?.slice(0, 1)?.toUpperCase()}</span>

              )
            }
        </div>
      </div>
      <div className="right">
      <div className="inputContainer">
      <textarea
        name="comment"
        id="comment"
        cols="30"
        rows="10"
        value={replyCommentValue}
        onChange={(e) => setReplyCommentValue(e.target.value)}
        placeholder={translateText("add_your_comment")}
      ></textarea>

     
<div className="upload-featue"> 
<div className="selectedImageReply">
{selectedGifReply && (
   
  <>
    <button className="removeButton" onClick={handleRemoveSelectionReply}>
   <CloseIcon />
 </button>
 <img src={selectedGifReply.images.fixed_height.url} alt="Selected Gif" />
  </>
  





)}
{imagePreviewReply && (


<>

<button className="removeButton" onClick={handleRemoveSelectionReply}>
   <CloseIcon />
 </button>
 <img src={imagePreviewReply} alt="Selected Image" />
</>




)}
 </div>
 {/* <div className="button-container-action"> */}
   <div className="actions">
   
    <div className="buttonContainer">
    <button className="cancel" onClick={cancelReplyHandler}> 
                    <Translate textKey={'cancel'}/>
                </button>
          <label htmlFor="reply_file" className="customFileUploadReply">
            <PhotoCamera /> 
          </label>
          <input type="file" id="reply_file" accept="image/*" onChange={handleFileChangeReply}  ref={fileInputRefReply} />

          <button className="giphyButton"  onClick={handleGiphyButtonClickReply}>
            <Gif /> 
          </button>

          <button className="submit" onClick={replyCommentHandler}>
             <Translate textKey={'reply'}/>
              </button>
        </div>
  
             
    </div>
      
      {/* </div> */}


</div>
   
{isGiphyModalOpenReply && (
<div className="customModal">
 <div className="modalContent">
   <button className="closeButton" onClick={() => setIsGiphyModalOpenReply(false)}>
     &times;
   </button>
   <GiphySearchbox
         apiKey={gifApiKey}
     onSelect={onGifSelectReply}
     searchPlaceholder="Search"
     masonryConfig={[
       { columns: 2, imageWidth: 110, gutter: 5 },
       { mq: '700px', columns: 3, imageWidth: 120, gutter: 5 }
     ]}
   />
 </div>
</div>
)}
    </div>
        {/* <div className="inputContainer">
          <textarea
            name="comment"
            id="comment"
            value={replyCommentValue || ""}
            onChange={(e) => setReplyCommentValue(e.target.value)}
            cols="30"
            rows="10"
            placeholder="Add your comment"
          ></textarea>
          <div className="actions">
           
            <div className="buttonContainer">
                <button className="cancel" onClick={cancelReplyHandler}> 
                    cancel
                </button>
              <button className="submit" onClick={replyCommentHandler}>
              Leave Reply
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ReplyField;
