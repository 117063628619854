import React, { useEffect, useState ,useRef} from "react";
import initializeFirebase from '../../../firebaseConfig';
import { ref, onValue, set, push, off } from "firebase/database";
import { useSelector } from "react-redux";
import { getCurrentUTCTimestamp, timeDifferenceWithCurrenttime } from "../../../utils/utils";
import CommentReply from "./ReplyField";
import CommentItem from "./CommentItem";
import {ToastContainer, toast } from "react-toastify";

import GiphySearchbox from 'react-giphy-searchbox';

import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Gif from '@mui/icons-material/Gif';
import CloseIcon from '@mui/icons-material/Close';
import { commentGifUploadThumbnail, commentImageUploadThumbnail } from "../service";
import Translate from "../../../Components/MultiLanguage/Translate";
import { useTranslateText } from "../../../Components/MultiLanguage/Translate";
let sortType = ""


const Comment = ({ videoId , projectInfo  }) => {


  const firebaseDB = initializeFirebase(projectInfo);
  const userDetails = useSelector((state) => state?.userDetails?.value);
  const user = useSelector((state) => state?.user?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [comments, setComments] = useState([]);
  const [commentInputValue, setCommentInputValue] = useState();
  const [selectedSort, setSelectedSort] = useState("default");
  const [selectedGif, setSelectedGif] = useState(null);
  const [imageDimensions, setImageDimensions] = useState(null);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isGiphyModalOpen, setIsGiphyModalOpen] = useState(false);
  const appInfo = {
    projectDetails: projectInfo,
    accessToken:accessToken
  };
  const gifApiKey = projectInfo?.projectConfig?.config?.GIPHY_API_KEY
  const fileInputRef = useRef(null); 
  const translateText = useTranslateText();
  const onGifSelect = async (item) => {
    
  
    const gifUrl =item?.images?.fixed_width_small?.url;
    const response = await commentGifUploadThumbnail(gifUrl, appInfo);
    if(response.data.data){
      setImageDimensions({
        height:parseInt(item?.images?.fixed_width_small?.height),
        width:parseInt(item?.images?.fixed_width_small?.width),
        url:response.data.data
      }  )
      setSelectedGif(item);
      setImage(null); // Ensure only one of image or GIF is selected
      setImagePreview(null);
      setIsGiphyModalOpen(false); // Close modal after selection
    }

  
    
     
     
  };

  const handleFileChange = async(e) => {
    let imageData =[]
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (!allowedExtensions.exec(e?.target?.files[0]?.name)) {
      // alert(<Translate textKey={'invalid_extension_message'}/>);
    } else {
      const files = e?.target?.files;
      setSelectedGif(null); // Ensure only one of image or GIF is selected
      const response = await commentImageUploadThumbnail(files[0],appInfo)
      if(response.data.data){
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new window.Image();
        img.onload = () => {
          setImageDimensions({ width: parseInt(img.width), height: parseInt(img.height)
            , url:response.data.data
             })
         };
        img.src = reader.result;
        setImagePreview(reader.result); // Set preview URL
      };
 

      setImage(files[0]);
      reader.readAsDataURL(files[0]);
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
    }
    }
  }
  };
  useEffect(() => {
    const firebaseDB = initializeFirebase(projectInfo);
    const dbRef = ref(firebaseDB, `comments/${videoId}`);
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      const commentArray = [];
      if (snapshot.exists()) {
        snapshot.forEach((childSnapshot) => {
          let keyName = childSnapshot.key;
          let data = childSnapshot.val();
         
          commentArray.push({ ...data, id: keyName, videoId: videoId });
        });
        if (sortType === "oldest") {
          setComments(commentArray?.filter(item => item?.delete_status !== true));

        } else {
          setComments(commentArray?.reverse()?.filter(item => item?.delete_status !== true));

        }
      } else {
        setComments(null)
      }
    });

    return () => off(dbRef)
  }, [videoId , projectInfo]);

  const postCommentHandler = async () => {
    if (commentInputValue || selectedGif || image) {
   
      let comment_data = {
        comment_text:  commentInputValue || '',
        user_id: Number(user),
        created_at: getCurrentUTCTimestamp(),
   
      }
      if(imageDimensions != null){
        comment_data.image = imageDimensions
      }

      push(ref(firebaseDB, `comments/${videoId}`),comment_data).then(() => {
        setCommentInputValue('');
        setSelectedGif(null);
        setImage(null);
        setImagePreview(null);
      });
    } else {
      toast.error(<Translate textKey={'comment_or_image_required'}/>, {
        position: 'top-center',
      });
    }
  };
  // const postCommentHandler = () => {
  //   if (commentInputValue) {
  //     push(ref(firebaseDB, `comments/${videoId}`), {
  //       comment_text: commentInputValue,
  //       user_id: Number(user),
  //       created_at: getCurrentUTCTimestamp(),
  //     }).then(() => {
  //       setCommentInputValue("")
  //     });
  //   } else {
  //     toast.error("Comment Field is required!", {
  //       position: "top-center"
  //     })
  //   }
  // };

  const commentsSortHandler = (type) => {
    if (type === "default" || type === "newest") {
      if (selectedSort === "oldest") {
        setComments(comments?.reverse()?.filter(item => item?.delete_status !== true))
      } else {
        setComments(comments?.filter(item => item?.delete_status !== true))
      }
    } else if (type === "oldest") {
      setComments(comments?.reverse()?.filter(item => item?.delete_status !== true))

    }
  }

  const sortHandleChange = (item) => {
    const { value } = item?.target;
    setSelectedSort(value)
    commentsSortHandler(value)
    sortType = value
  }
  const handleGiphyButtonClick = () => {
    console.log('GIF button clicked');
    setIsGiphyModalOpen(true);
  };
  const handleRemoveSelection = () => {
    setSelectedGif(null);
    setImage(null);
    setImagePreview(null);
  };
  return (
    <div className="commentsContainer">
       <ToastContainer/>
      <div className="top">
        <div className="head">
          <span className="commentsCount">{comments ? comments?.length : "0"} <Translate textKey={'comments'}/></span>
          <div className="sortContainer">
            <select name="sort" id="sort" onChange={(e) => sortHandleChange(e)}>
              <option value="default" ><Translate textKey={'sort_by'}/>..</option>
              <option value="newest" ><Translate textKey={'sort_by_newest'}/></option>
              <option value="oldest"><Translate textKey={'sort_by_oldest'}/></option>
            </select>
          </div>
        </div>
        <div className="textContainer">
          <div className="imageContainer">
            {
              userDetails?.user_image ? (
                <img src={userDetails?.user_image} alt="Profile" />
              ) : (
                <span className="nameAvatar">{userDetails?.first_name?.slice(0, 1)?.toUpperCase()}</span>

              )
            }
          </div>
          {/* <input type="file" id="file" accept="image/*" onChange={(e) => handleFileChange(e)} /> */}
          {/* <Grid width={800} columns={3} fetchGifs={fetchGifs} /> */}
          {/* <div className="inputContainer">
            <textarea
              name="comment"
              id="comment"
              value={commentInputValue}
              onChange={(e) => setCommentInputValue(e.target.value)}
              cols="30"
              rows="10"
              placeholder="Add your comment"
            ></textarea>
            <div className="actions">
              <div className="buttonContainer">
                <button className="submit" onClick={postCommentHandler}>
                  Post comment
                </button>
              </div>
            </div>
          </div>  */}
<div className="inputContainer">
      <textarea
        name="comment"
        id="comment"
        cols="30"
        rows="10"
        value={commentInputValue}
        onChange={(e) => setCommentInputValue(e.target.value)}
        placeholder={translateText("add_your_comment")}
      ></textarea>

     
<div className="upload-featue"> 
<div className="selectedImage">
{selectedGif && (
   
  <>
    <button className="removeButton" onClick={handleRemoveSelection}>
   <CloseIcon />
 </button>
 <img src={selectedGif.images.fixed_height.url} alt="Selected Gif" />
  </>
  





)}
{imagePreview && (


<>

<button className="removeButton" onClick={handleRemoveSelection}>
   <CloseIcon />
 </button>
 <img src={imagePreview} alt="Selected Image" />
</>




)}
 </div>
 {/* <div className="button-container-action"> */}
   <div className="actions">
   
    <div className="buttonContainer">
          <label htmlFor="file" className="customFileUpload">
            <PhotoCamera /> 
          </label>
          <input type="file" id="file" accept="image/*" onChange={handleFileChange}   ref={fileInputRef} />

          <button className="giphyButton"  onClick={handleGiphyButtonClick}>
            <Gif /> 
          </button>
        </div>
        <button className="submit" onClick={postCommentHandler}>
          <Translate textKey={'post'}/>
        </button>
    </div>
      
      {/* </div> */}


</div>
   
{isGiphyModalOpen && (
<div className="customModal">
 <div className="modalContent">
   <button className="closeButton" onClick={() => setIsGiphyModalOpen(false)}>
     &times;
   </button>
   <GiphySearchbox
     apiKey={gifApiKey}
     onSelect={onGifSelect}
     searchPlaceholder="Search"
     masonryConfig={[
       { columns: 2, imageWidth: 110, gutter: 5 },
       { mq: '700px', columns: 3, imageWidth: 120, gutter: 5 }
     ]}
   />
 </div>
</div>
)}
    </div>

        </div>
      </div>

      <div className="bottom">
        <div className="comments">
          {comments?.map((item, index) => (
            <CommentItem data={item} projectInfo={projectInfo} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Comment;