import React, { memo, Fragment } from "react";
import { useSelector } from "react-redux";
import { useWindowWidth } from "@react-hook/window-size";

// react-router
import { Link } from "react-router-dom";

// img
// import img1 from "../../assets/imagesnew/logo.webp";
// import hostar from "../../assets/imagesnew/logo-hotstar.webp";
// import prime from "../../assets/imagesnew/logo-prime.webp";
// import hulu from "../../assets/imagesnew/logo-hulu.webp";

const Logo = memo((props) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const windowSize = useWindowWidth();

  return (
    <Fragment>
      <div className="logo-default">
        <Link className="navbar-brand text-primary" to="/home">
          <img
            className="img-fluid logo" 
            style={ projectInfo?.projectConfig?.pubid === 50127 && windowSize > 360
                ? { height: '4rem', maxHeight: '4rem' }
                : {}
            }
            src={props.logo}
            loading="lazy"
            alt="streamit"
          />
        </Link>
      </div>
      {/* <div className="logo-hotstar">
        <Link className="navbar-brand text-primary" to="/home">
          <img
            className="img-fluid logo"
            src={hostar}
            loading="lazy"
            alt="streamit"
          />
        </Link>
      </div>
      <div className="logo-prime">
        <Link className="navbar-brand text-primary" to="/home">
          <img
            className="img-fluid logo"
            src={prime}
            loading="lazy"
            alt="streamit"
          />
        </Link>
      </div>
      <div className="logo-hulu">
        <Link className="navbar-brand text-primary" to="/home">
          <img
            className="img-fluid logo"
            src={hulu}
            loading="lazy"
            alt="streamit"
          />
        </Link>
      </div> */}
    </Fragment>
  );
});

Logo.displayName = "Logo";
export default Logo;
