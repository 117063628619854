import logo from "./logo.svg";
import "./App.css";
import LayoutsRoutes from "./Routes/LayoutsRoutes";
import "swiper/css";
import "swiper/css/navigation";
import * as service from "./network/service";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectInfo } from "./Redux/ProjectInfo/ProjectInfoSlice";
import { getAccessToken } from "./Redux/AToken/ATokenSlice";
import Fingerprint2 from "fingerprintjs2";
import { checkOperatingSystem, getSessionId, handleChangeColor } from "./utils/utils";
import { getUser } from "./Redux/UserSlice/UserSlice";
import { getUserDetails } from "./Redux/UserDetailsSlice/UserDetailsSlice";
import { getSessionIdValue } from "./Redux/SessionId/sessionIdSlice";
import { getLanguageModal } from "./Redux/LanguageModal/languageModalSlice";
import { toast } from "react-toastify";
import Loading from "./Components/Loading/Loading";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useLocation } from "react-router-dom";
import i18n from 'i18next';
import { useNavigate } from "react-router-dom";




function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageModal = useSelector(
    (state) => state?.languageModal?.value

  );




  const [isAuthenticated, SetIsAuthenticated] = useState(false);
  const [projectInfo, setProjectInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [scroll, setScroll] = useState(0);
  const user = localStorage.getItem("userId");
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const loginTicket = urlParams.get("ticket");
  const affliateId = urlParams.get("af_id");
  const androidToken = urlParams.get("antkn");
  const qr = urlParams.get("qr");
  const appInfo = {
    projectDetails: projectInfo,
  };

  useEffect(() => {
    if (Object.keys(projectInfo)?.length > 0) {
      handleChangeColor(projectInfo?.projectConfig?.config);
    }
  }, [projectInfo]);

  useEffect(() => {
    if (affliateId) {
      localStorage.setItem("affliateId", affliateId);
      localStorage.setItem("affliateUrl", encodeURIComponent(window.location.href));
      if (checkOperatingSystem() === "android" || checkOperatingSystem() === "iPhone") {
        localStorage.setItem(
          "affliateRedirectPath",
          location?.pathname
        );
      }
    }
    let info = {};
    setLoading(true);
    if (user) {
      dispatch(
        getUser({
          user: user,
        })
      );
    }

    getAppInfo();
  }, []);

  useEffect(() => {
    if (Object.keys(projectInfo)?.length > 0) {
      if (loginTicket) {
        externalWebsiteLogin()
      }
      else if (!localStorage.getItem("gid")) {
        getGuestUser();
      } else {
        getAuthentication();
      }
    }
  }, [projectInfo]);

  useEffect(() => {

    // Initialize i18next with a default language
    i18n.init({
      lng: languageModal?.selectedLanguageCode,
      resources: {},
    });
    let progressBarHandler = () => {

      const totalScroll = document.documentElement.scrollTop;
      const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolls = `${totalScroll / windowHeight}`;

      setScroll(scrolls);
    }
    window.addEventListener("scroll", progressBarHandler);
    return () => window.removeEventListener("scroll", progressBarHandler);

  }, []);



  const storedData = localStorage.getItem('selectedLanguageId');
  const languagestored = localStorage.getItem('selectedLanguageName');
  const storedLanguage = localStorage.getItem('selectedLanguageCode');

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguageCode');

    if (storedLanguage) {

      dispatch(getLanguageModal({ languageModal: { ...languageModal, selectedLanguageCode: storedLanguage } }));
      i18n.changeLanguage(storedLanguage);
    } else {
      console.log("No language in localStorage");

    }
  }, []);

  const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, '');

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `${r}, ${g}, ${b}`;
  };

  const getAppInfo = async () => {
    let info = {};
    setLoading(true);
    try {
      const appInfoResponse = await service.fetchAppInfo();
      if (appInfoResponse?.status === 200) {
        info.projectConfig = appInfoResponse?.data?.data;

        Fingerprint2.get(async (component) => {
          var values = component.map((component) => {
            return component.value;
          });
          var murmur = Fingerprint2.x64hash128(values.join(""), 31);

          info.device_id = murmur;
          const geoInfo = await getGeoInfo();
          info.geoInfo = geoInfo;
          dispatch(
            getProjectInfo({
              projectInfo: info,
            })
          );
          setProjectInfo(info);
          i18n.addResourceBundle(languageModal?.selectedLanguageCode, 'translation', info?.projectConfig?.config?.LANGUAGE_KEYWORDS, true, true);
        });
      }

      if (appInfoResponse?.data?.data?.config?.BUTTON_COLOR) {
        document.documentElement.style.setProperty('--bs-primary', appInfoResponse?.data?.data?.config?.BUTTON_COLOR);
        if (appInfoResponse?.data?.data?.config?.BUTTON_SELECTION_COLOR) {
          const buttonColor = hexToRgb(appInfoResponse?.data?.data?.config?.BUTTON_SELECTION_COLOR)
          document.documentElement.style.setProperty('--bs-primary-rgb', buttonColor);
        }
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  const externalWebsiteLogin = async () => {
    try {
      const response = await service.updateExternalUser(appInfo, loginTicket);
      if (response?.status === 200) {
        localStorage.setItem("userId", response?.data?.data[0]?.user_id)
        dispatch(
          getUser({
            user: response?.data?.data[0]?.user_id
          })
        )
        await getAuthentication(response?.data?.data[0]?.user_id, response?.data?.data[0]?.user_email)

        if (qr) {
          navigate(`/tv?code= ${qr}`);
        } else {
          if (localStorage.getItem('redirectFromNewCheckoutPage') == 'true') {
            navigate(`/checkout`);
          }
        }

      }
    } catch (err) {
      if (!localStorage.getItem("gid")) {
        getGuestUser();
      } else {
        getAuthentication();
      }
    }
  }

  const getGeoInfo = async () => {
    try {
      const geoInfoResponse = await service.fetchGeoInfo();
      if (geoInfoResponse?.status === 200) {
        return geoInfoResponse?.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  const getGuestUser = async () => {
    try {
      const guestResponse = await service.guestUser(appInfo);
      if (guestResponse?.status === 200) {
        localStorage.setItem("gid", guestResponse?.data?.user_id);
        if (localStorage?.getItem("gid")) {
          getAuthentication(guestResponse?.data?.user_id);
        }
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  const getAuthentication = async (userId, userEmail) => {
    try {
      const authenticationResponse = await service.authenticate(appInfo);
      if (
        authenticationResponse?.status === 200 &&
        authenticationResponse?.data?.message !== "Invalid user"
      )
       {
        dispatch(
          getLanguageModal({
            languageModal: {
             selectedLanguageCode: authenticationResponse?.data?.user_language?.short_code,
              }
            }) )
        dispatch(
          getAccessToken({
            accessToken: authenticationResponse?.data?.token,
          })
        );
        dispatch(
          getUserDetails({
            userDetails: authenticationResponse?.data,
          })
        );
        SetIsAuthenticated(true);
        setLoading(false);
        const sessionId = await getSessionId(appInfo);
        dispatch(
          getSessionIdValue({
            sessionId: sessionId,
          })
        );
        appInfo["sessionId"] = await sessionId;
        appInfo["accessToken"] = await authenticationResponse?.data?.token;
        if (!localStorage.getItem("applaunch")) {
          updateAppLaunch();
        }

        if (loginTicket) {
          updateDeviceAnalytics(userId, appInfo, userEmail);
        } else if (!localStorage.getItem("device_analytics")) {
          updateDeviceAnalytics(userId, appInfo, userEmail);
        } else {
          // nothing to do
        }

      } else {
        toast.error("Invalid User", {
          position: "bottom-center",
        });
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const updateAppLaunch = async () => {
    try {
      const response = await service.applaunchEvent("POP01", appInfo);
      if (response?.status === 200) {
        localStorage.setItem("applaunch", true);
      } else {
        localStorage.removeItem("applaunch");
      }
    } catch (err) {
      localStorage.removeItem("applaunch");
    }
  };

  const updateDeviceAnalytics = async (userId, userEmail) => {
    try {
      const response = await service.analytics2(userId, appInfo, userEmail);
      if (response?.status === 200) {
        localStorage.setItem("device_analytics", "true");
      } else {
        localStorage.removeItem("device_analytics");
      }
    } catch (err) {
      localStorage.removeItem("device_analytics");
    }
  };


  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <div
        style={{
          backgroundColor: "#000",
        }}
      >
        <div id="progressBarContainer">
          <div id="progressBar" style={{ transform: `scale(1, ${scroll})`, }} />
        </div>
        {isAuthenticated && (

          <GoogleOAuthProvider clientId={projectInfo?.projectConfig?.config?.GOOGLE_CLIENT_ID ? projectInfo?.projectConfig?.config?.GOOGLE_CLIENT_ID : "test"}>
            <LayoutsRoutes />
          </GoogleOAuthProvider>
        )}
      </div>
    </>
  );
}

export default App;
