import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Link} from "react-router-dom";
import Translate from "../MultiLanguage/Translate"
import { useWindowWidth } from "@react-hook/window-size";

const HeaderAccountsPage = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  const navigate = useNavigate();
  const location = useLocation();
  const windowSize = useWindowWidth();
  const redirectPage =projectInfo?.projectConfig?.config?.INITIAL_PAGE
  return (
    <header className="accountsPageHeader">
      <div className="left">
        <div className="logoContainer">
          <Link to={redirectPage==="LOGIN"?"/login":"/home"}>
            <h1>
              <img src={projectInfo?.projectConfig?.config?.LOGO}
                          style={ projectInfo?.projectConfig?.pubid === 50127 && windowSize > 360
                            ? { height: '4rem', maxHeight: '4rem', width: '200%', maxWidth: '200%' }
                            : {}
                        }
               alt="Logo" />
            </h1>
          </Link>
        </div>
      </div>
      <div className="right">
        {location?.pathname === "/register" ? (
          <button onClick={() => navigate("/login",{state:{...location?.state}})}><Translate textKey={'login'} /></button>
        ) : (
          <button onClick={() => navigate("/register",{state:{...location?.state}})}><Translate textKey={'register'} /></button>
        )}
      </div>
    </header>
  );
};

export default HeaderAccountsPage;
