import React, { useEffect, useRef, useState, } from "react";
import { Row, Col, Container, Nav, Tab, Form } from "react-bootstrap";
import Dialog from "@mui/material/Dialog";
import { ToastContainer, toast } from "react-toastify";
import DialogContent from "@mui/material/DialogContent";
import Zoom from "@mui/material/Zoom";
import { useDispatch, useSelector } from "react-redux";
import { getMoreInfo } from "../../Redux/MoreInfo/moreInfoSlice";
import { useLocation, useNavigate, useParams, Link, } from "react-router-dom";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/twitter.svg";
import { ReactComponent as ClipBoard } from "../../assets/Icons/clipBoard.svg";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { checkOperatingSystem, convertDateAndTimeToLocal, getEventInfo, imageUrlCheck } from "../../utils/utils";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";
import { ReactComponent as ShareIcon } from "../../assets/Icons/share.svg";

import parse from "html-react-parser";
import MainLoading from "../../Components/Loading/MainLoading";
import { authenticate, getPlayerToken, getUserSubscription, updateWatchlistData } from "../../network/service";
import VideoJS from "../../Components/VideoJsPlayer/VideoJS";
import { useWindowWidth } from "@react-hook/window-size";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getMoreInfoOther } from "../../Redux/MoreInfo/moreInfoOtherSlice";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import TrailerModal from "../../Components/Modals/TrailerModal";
import LogoutAllModal from "../../Components/Modals/LogoutAllModal";
import { getEventMoreInfo } from "../../Redux/MoreInfo/eventMoreInfoSlice";
import Translate from "../../Components/MultiLanguage/Translate";
import { Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { getShowDetails, getSimilarVideos, updateLike } from "../ShowDetailsScreen/service";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom direction="down" ref={ref} {...props} />;
});
const EventDetailsPage = ({ fetchHomeData, modalShow }) => {
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const accessToken = useSelector((state) => state?.accessToken?.value);
    const user = useSelector((state) => state?.user?.value);
    const isMoreInfoOther = useSelector((state) => state?.moreInfoOther?.value);
    const isEventMoreInfo = useSelector((state) => state?.eventMoreInfo?.value);

    const userSubscriptionData = useSelector((state) => state?.userSubscription?.value);
    const isMoreInfo = useSelector((state) => state?.overlay?.value);
    const [showDetails, setShowDetails] = useState({});
    const [playerToken, setPlayerToken] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [loading, setLoading] = useState(true);
    const [selectedVideoDetails, setSelectedVideoDetails] = useState();
    const [initialVideoSubscriptions, setInitialVideoSubscriptions] = useState([]);
    const [isSubscribedUser, setIsSubscribedUser] = useState();
    const [isFreeVideo, setIsFreeVideo] = useState();
    const [subscribeButton, setSubscribeButton] = useState();
    const [videoPlayer, setVideoPlayer] = useState();
    const [videoStarted, setVideoStarted] = useState(false);
    const [isMute, setIsMute] = useState(true);
    const [logoutModal, setLogoutModal] = useState(false);
    const [modalType, setModalType] = useState({ type: "logoutAll" });
    const [open, setOpen] = useState(false);
    const [pageURL, setPageURL] = useState("");
    const [isShareActive, setIsShareActive] = useState(false);
    const [watchlistStatus, setWatchlistStatus] = useState();
    const [favouriteStatus, setFavouriteStatus] = useState()


    const windowSize = useWindowWidth();
    const scrollRef = useRef(null);
    const shareRef = useRef(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const { eventId } = useParams();
    const navigate = useNavigate();
    let eventUniqueId = isEventMoreInfo?.eventId ? isEventMoreInfo?.eventId : eventId;
    const appInfo = {
        projectDetails: projectInfo,
        accessToken: accessToken,
    };
    const dataType = location?.state?.data?.type;
    const addToFavourites = () => {
        if (user) {
            updateFavourites(1);
        } else {
            navigate("/login", { state: { path: location?.pathname } });
        }
    };
    const removeFromFavourites = () => {
        updateFavourites(0);
    };

    const updateFavourites = async (flag) => {
        const response = await updateLike(appInfo, showDetails?.show_id, flag);
        if (response?.status === 200) {
            if (flag === 1) {
                setFavouriteStatus("liked");
            } else {
                setFavouriteStatus("disliked");
            }
        }
    };

    const updateWatchlist = async (flag) => {
        try {
            const response = await updateWatchlistData(appInfo, showDetails?.show_id, flag);

            if (response?.status === 200) {
                if (flag === 1) {
                    toast.success("Added to mylist", {
                        position: "bottom-center",
                        toastId: "success1",
                    });
                    setWatchlistStatus(1);
                } else {
                    toast.success("Removed from mylist", {
                        position: "bottom-center",
                    });
                    setWatchlistStatus(0);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };
    const addToWatchlist = () => {
        if (user) {
            updateWatchlist(1);
        } else {
            navigate("/login", { state: { path: location?.pathname } });
        }
    };
    const removeFromWatchlist = () => {
        updateWatchlist(0);
    };
    useEffect(() => {
        window.scroll(0, 0);

        setPageURL(window.location.href);

        fetchUserSubscriptionDetails();
        return () => {
            handleReset();
        };
    }, []);

    useEffect(() => {
        if (isMoreInfoOther?.eventId) {
            eventUniqueId = isMoreInfoOther?.eventId;
        }
    }, [isMoreInfoOther?.eventId]);

    useEffect(() => {
        if (eventUniqueId) {
            setOpen(true);
            if (eventUniqueId !== "null" && eventUniqueId !== "undefined") {
                fetchEventInfo();
            }
        }
    }, [eventUniqueId]);


    useEffect(() => {
        if (showDetails) {
            if (Object.entries(showDetails).length > 0) {
                if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
                    if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
                        let isSubscribedUser;
                        if (showDetails?.subscriptions?.length > 0) {
                            isSubscribedUser = userSubscriptionData?.data?.length > 0
                                ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                                : false;
                            if (isSubscribedUser === true) {
                                setSubscribeButton(false)
                                setIsSubscribedUser(true)
                            } else {
                                setIsSubscribedUser(false)
                                setInitialVideoSubscriptions(showDetails?.subscriptions)
                                if (showDetails?.free_event === true) {
                                    setSubscribeButton(false)
                                } else {
                                    setSubscribeButton(true)
                                }
                            }
                        } else {
                            setSubscribeButton(false)
                            setIsSubscribedUser(true)
                        }
                    } else {
                        setSubscribeButton(false)
                        setIsSubscribedUser(true)
                    }
                } else {
                    if (userSubscriptionData?.login_needed === false) {
                        setSubscribeButton(false)
                        setIsSubscribedUser(true)
                    }
                }
            }
        }
    }, [showDetails])


    const fetchEventInfo = async () => {
        try {
            const response = await getEventInfo(appInfo, eventUniqueId);
            setShowDetails(response?.data?.data)
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }


    // const handleClose = (type) => {
    //     document.body.className = "hideScroll";

    //     handleReset();
    //     setOpen(false);
    //     let path = location?.pathname
    //     if (path.split("/")[1] === "category") {
    //         setTimeout(() => {
    //             dispatch(
    //                 getEventMoreInfo({
    //                     isModal: false,
    //                     eventId: null,
    //                 })
    //             );
    //         }, 600);
    //     } else {
    //         if (type !== "showDetails") {
    //             navigate("/home");
    //         }
    //         setTimeout(() => {
    //             dispatch(
    //                 getEventMoreInfo({
    //                     eventMoreInfo: false,
    //                 })
    //             );
    //         }, 500);
    //     }
    //     setTimeout(() => {
    //         setShowDetails(null);
    //     }, 600);
    // };

    const handleReset = () => {
        setVideoPlayer(null);
        setIsMute(false);
        setVideoStarted(false);
    };

    const subscriptionCheck = (userSub, videoSub) => {
        const hasMatch = userSub.some((user) => videoSub?.some((video) => user?.sub_id === video?.subscription_id));
        return hasMatch;
    };

    const playVideo = async () => {
        if (showDetails?.type !== "UPCOMING") {
            navigate("/player",
                { state: { eventId: showDetails?.event_id } }
            );
        } else {
            toast.error(<Translate textKey={'event_not_started_yet'}/>)
        }
    };

    const redirectToLogin = () => {
        dispatch(
            getUser({
                user: null,
            })
        );
        localStorage.removeItem("userId");
        tokenAuthenticate();
        navigate("/login", {
            state: { path: location?.pathname, showId: eventUniqueId },
        });
    };

    const tokenAuthenticate = async () => {
        const response = await authenticate(appInfo);
        if (response?.status === 200) {
            dispatch(
                getAccessToken({
                    accessToken: response?.data?.token,
                })
            );
        }
    };

    const handleSubscribe = (data) => {
        if (user) {
            localStorage.setItem("selectedSubId", data?.subscription_id);
            localStorage.setItem("selectedAmount", data?.price);
            localStorage.setItem("eventId", showDetails?.event_id);
            localStorage.setItem("vanityUrl", eventUniqueId);
            localStorage.setItem("eventType", showDetails?.type);
            navigate("/payment", { state: { subscription: { ...data, is_coupon: false } } });
        } else {
            navigate("/login", {
                state: { path: "/event/" + eventUniqueId, showId: eventUniqueId },
            });
        }
    };

    const subscriptionCheckAndVideoPlay = async () => {
        if (user) {
            if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
                if (userSubscriptionData?.forcibleLogout === true) {
                    setLogoutModal(true);
                    setModalType({
                        type: "logoutAll",
                        heading: <Translate textKey={'reached_maximum_device_message'} />,
                    });
                } else if (userSubscriptionData?.session_expired === true) {
                    setModalType({ type: "redirect" });
                    setLogoutModal(true);
                    setTimeout(() => {
                        redirectToLogin();
                    }, 1000);
                } else {
                    if (showDetails?.subscriptions?.length > 0) {
                        let isSubscribedUser;
                        isSubscribedUser =
                            userSubscriptionData?.data?.length > 0
                                ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                                : false;

                        if (isSubscribedUser === true) {
                            playVideo();
                        } else {
                            if (showDetails?.free_event === true) {
                                playVideo();
                            } else {
                                localStorage.setItem("eventId", showDetails?.event_id);
                                localStorage.setItem("vanityUrl", eventUniqueId);
                                localStorage.setItem("eventType", showDetails?.type);

                                navigate("/subscription", {
                                    state: { eventId: showDetails?.event_id },
                                });
                            }
                        }
                    } else {
                        if (showDetails) {
                            playVideo();
                        }
                    }
                }
            } else {
                playVideo();
            }
        } else {
            navigate("/login", {
                state: { path: "/event/" + eventUniqueId, showId: eventUniqueId },
            });
        }
    };

    const watchVideoHandler = async () => {
        if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
            subscriptionCheckAndVideoPlay();
        } else {
            if (userSubscriptionData?.login_needed === false) {
                playVideo();
            } else if (userSubscriptionData?.login_needed === true) {
                setModalType({ type: "videoWatchLimitExpired" });
                setLogoutModal(true);
            } else {
                subscriptionCheckAndVideoPlay();
            }
        }
    };

    const fetchUserSubscriptionDetails = async () => {
        const userSubResponse = await getUserSubscription(appInfo);
        if (userSubResponse?.status === 200) {
            dispatch(
                getUserSubscriptionData({
                    userSubscription: userSubResponse?.data,
                })
            );
        }
    };

    const showDetailsRedirect = () => {
        navigate(`/show-details/${showDetails?.vanity_url}`)

    }

    if (loading) {
        return <MainLoading />;
    }
    return (
        <Fragment>
            <div
                className="screenBanner iq-breadcrumb d-flex align-items-center justify-content-center"
                style={{ backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1.5)), url(${showDetails?.thumbnail_350_200})` }}
            >
            </div>
            <div className="details-part">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="trending-info mt-4 pt-0 pb-4">
                                <Row>
                                    <Col md="9" className="mb-auto">
                                        <div className="d-block d-lg-flex align-items-center mb-2">
                                            <h2 className="trending-text fw-bold texture-text text-uppercase my-0 fadeInLeft animated d-inline-block">
                                                {showDetails?.event_name}
                                            </h2>
                                        </div>
                                        {/* {(!isSubscribedUser || showDetails?.free_event === true)  && (dataType !=="UPCOMING_EVENT") && showDetails?.type !== "ENDED" ? subscribeButton ? (
                                           
                                            <div className="iq-button ">
                                                <div
                                                    onClick={() => watchVideoHandler(selectedVideoDetails)}
                                                    className="btn text-uppercase position-relative px-4 py-3"
                                                >
                                                    <span className="button-text"><Translate textKey={'play_now'} /></span>
                                                    <i className="fa-solid fa-play"></i>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="iq-button " >
                                                <div
                                                    onClick={() => watchVideoHandler(selectedVideoDetails)}
                                                    className="btn text-uppercase position-relative px-4 py-3"
                                                >
                                                    <span className="button-text"><Translate textKey={'play_now'} /></span>
                                                    <i className="fa-solid fa-play"></i>
                                                </div>
                                            </div>
                                        ) : null} */}

                                        {dataType !== "UPCOMING_EVENT" && showDetails?.type !== "ENDED" &&
                                            <div className="iq-button " >
                                                <div
                                                    onClick={() => watchVideoHandler(selectedVideoDetails)}
                                                    className="btn text-uppercase position-relative px-4 py-3"
                                                >
                                                    <span className="button-text"><Translate textKey={'play_now'} /></span>
                                                    <i className="fa-solid fa-play"></i>
                                                </div>
                                            </div>}

                                        <ul className="p-0 mt-2 list-inline d-flex flex-wrap movie-tag">
                                            {showDetails?.categories?.length > 0 && dataType !== "UPCOMING_EVENT" && showDetails?.categories?.map((item, index) => {
                                                return (
                                                    <li key={index} className="trending-list">
                                                        <Link
                                                            className="text-primary text-capitalize"
                                                        >
                                                            {item.category_name}
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                        <div className="d-flex flex-wrap align-items-center text-white text-detail flex-wrap mb-4">
                                            {showDetails?.categories?.length > 0 && <span className="badge bg-secondary">{showDetails?.categories[0]?.category_name}</span>}
                                            {
                                                showDetails?.schedule_time && showDetails?.type !== "ENDED" &&
                                                <span className="ms-3 font-Weight-500 genres-info me-2">{convertDateAndTimeToLocal(showDetails?.schedule_time)}</span>
                                            }
                                            {!isSubscribedUser || selectedVideoDetails?.free_video === true ?
                                                (showDetails?.subscriptions?.length > 0 || selectedVideoDetails?.free_video === true ? (
                                                    <div className="subscriptionContainer">
                                                        {showDetails?.subscriptions?.length > 0 &&
                                                            showDetails?.subscriptions?.map((item, index) => (
                                                                <button className="px-2 my-2 mx-1 rounded bg-warning text-white" onClick={() => handleSubscribe(item)}>
                                                                    <span>{item?.subscription_text}</span>
                                                                </button>
                                                            ))}
                                                        {showDetails?.single_video === 1 && selectedVideoDetails?.free_video === true && (
                                                            <button className=" px-2 mx-1 rounded bg-success text-white " onClick={() => watchVideoHandler(selectedVideoDetails)}>
                                                                <span>
                                                                    <Translate textKey={'free'} />
                                                                </span>
                                                            </button>
                                                        )}
                                                    </div>
                                                ) : null) : null

                                            }
                                        </div>

                                        <div className="d-flex align-items-center gap-4 flex-wrap">
                                            <ul className="list-inline p-0 share-icons music-play-lists mb-n2 mx-n2">
                                                {<li className="share">
                                                    <span>
                                                        <i className="fa-solid fa-share-nodes"></i>
                                                    </span>
                                                    <div className="share-box">
                                                        <svg
                                                            width="15"
                                                            height="40"
                                                            viewBox="0 0 15 40"
                                                            className="share-shape"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M14.8842 40C6.82983 37.2868 1 29.3582 1 20C1 10.6418 6.82983 2.71323 14.8842 0H0V40H14.8842Z"
                                                                fill="#191919"
                                                            ></path>
                                                        </svg>
                                                        <div className="d-flex align-items-center">
                                                            <Link>
                                                                <FacebookShareButton url={window.location.href} quote={"Share"} className="facebook">
                                                                    <i className="fa-brands fa-facebook-f text-white"></i>
                                                                </FacebookShareButton>
                                                            </Link>
                                                            <Link>
                                                                <TwitterShareButton url={window.location.href} className="twitter">
                                                                    <i className="fa-brands fa-twitter text-white"></i>
                                                                </TwitterShareButton>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </li>}
                                                <li>
                                                    {favouriteStatus === "liked" ? <span title="Dislike Show" onClick={removeFromFavourites}>
                                                        <i className="fa-solid fa-heart text-danger"></i>
                                                    </span> : <span title="Like Show" onClick={addToFavourites}>
                                                        <i className="fa-solid fa-heart" ></i>
                                                    </span>}
                                                </li>
                                                <li>
                                                    {watchlistStatus === 1 ? <span title="Remove from My List" onClick={() => (removeFromWatchlist())}>
                                                        <i className="fa-solid fa-check" ></i>
                                                    </span> : <span title="Add to My List" onClick={() => (addToWatchlist())}>
                                                        <i className="fa-solid fa-plus" ></i>
                                                    </span>}
                                                </li>
                                                <ToastContainer />
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {showDetails?.description && <div className="content-details trending-info">
                                <Tab.Container defaultActiveKey="first">
                                    <Nav className="iq-custom-tab tab-bg-gredient-center d-flex nav nav-pills align-items-center text-center mb-5  list-inline">
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="first"
                                                variant=" d-flex align-items-center"
                                                id="nav-description-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-description"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-description"
                                                aria-selected="true"
                                            >
                                                {/* <Translate textKey={'description'}/> */}
                                              <Translate textKey={'description'}/>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane
                                            className=" fade show"
                                            eventKey="first"
                                            id="nav-description"
                                            role="tabpanel"
                                            aria-labelledby="nav-description-tab"
                                        >
                                            {showDetails?.description && (
                                                <p className="text-white">{parse(showDetails?.description?.split("\n")?.join("<br>"))}</p>
                                            )}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="cast-tabs">
            </div>


            <div className="my-4"></div>
        </Fragment>
    );
}




export default EventDetailsPage;
