import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import ReactPlayer from 'react-player';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import ShareIcon from '@mui/icons-material/Share';
import { useNavigate } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { onVideoPlayFunction } from "../../utils/utils";
import ShortComment from './ShortsComments/ShortComments';
import { likeHandler } from "./service";
import { Pause as PauseIcon, PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import Translate from "../../Components/MultiLanguage/Translate";

const ReactPlayerJS = ({
  id, src, like, comment, projectInfo,
  isActive, isVisible, title, desc,
  appInfo, shareUrl, vanityUrl,
  shouldCloseCommentModal, setShouldCloseCommentModal,
  liked_flag,
  poster,
  handleScroll,
  shortsContainerRef,
  debounce
}) => {
  const [playing, setPlaying] = useState(true);
  const [isCommentModalOpen, setCommentModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(like);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [pauseIconVisible, setPauseIconVisible] = useState(false);
  const [playIconVisible, setPlayIconVisible] = useState(false);
  const [commentCount, setCommentCount] = useState(comment);
  const [likeColor, setLikeColor] = useState(liked_flag);
  const playerRef = useRef(null);
  const navigate = useNavigate();
  const [loopCount, setLoopCount] = useState(0);

  useEffect(() => {
    if (shouldCloseCommentModal) {
      setCommentModalOpen(false);
      setShouldCloseCommentModal(false);
    }
  }, [shouldCloseCommentModal, setShouldCloseCommentModal]);

  useEffect(() => {
    setCommentCount(commentCount);
  }, [commentCount]);

  useEffect(() => {
    if (liked_flag === 'blue') {
      setLiked(true);
      setLikeColor('blue');
    } else {
      setLiked(false);
      setLikeColor('#fff');
    }
  }, [liked_flag]);

  const scrollShortsHandler = () => {
    if(shortsContainerRef){
      shortsContainerRef.current.scrollTo({
        top: 500,
        behavior: 'smooth', 
      });
    }
  }
  
  const handleError = () => {
    scrollShortsHandler();
  }
  
  const onVideoEnded = () => {
    setLoopCount((prev) => prev + 1);
    if (loopCount >= 1) { 
      scrollShortsHandler();
      setLoopCount(0); 
    } else {
      playerRef.current.seekTo(0); 
      setPlaying(true); 
    }
  };
  
  
  
  const handleLike = useCallback(async () => {
    try {
      let newLikedStatus;
      if (liked_flag === 'blue') {
        if (likeColor === '#fff') {
          newLikedStatus = true;
          setLikeColor('blue');
          setLikeCount(prevCount => prevCount + 1);
        } else {
          newLikedStatus = false;
          setLikeColor('#fff');
          setLikeCount(prevCount => prevCount - 1);
        }
      } else {
        newLikedStatus = !liked;
        setLikeColor(newLikedStatus ? 'blue' : '#fff');
        setLikeCount(prevCount => newLikedStatus ? prevCount + 1 : prevCount - 1);
      }
      setLiked(newLikedStatus);
      await likeHandler(id, newLikedStatus, appInfo);
    } catch (error) {
      console.error('Error updating like status:', error);
    }
  }, [liked, id, appInfo]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy text:', error);
    }
  };

  const openCommentModal = useCallback(() => setCommentModalOpen(true), []);
  const closeCommentModal = useCallback(() => setCommentModalOpen(false), []);
  const toggleDescription = useCallback(() => setIsExpanded(prev => !prev), []);
  const openShareModal = useCallback(() => setShareModalOpen(true), []);
  const closeShareModal = useCallback(() => setShareModalOpen(false), []);

  const handleVideoPress = () => {
    setPlaying(!playing);
    setShowIcons(true);
    setTimeout(() => setShowIcons(false), 500);
  };

  const shareOptions = useMemo(() => (
    <>
      <FacebookShareButton url={shareUrl} className="share-button facebook">
        <i className="fa-brands fa-facebook-f"></i>
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} className="share-button twitter">
        <i className="fa-brands fa-twitter"></i>
      </TwitterShareButton>
    </>
  ), [shareUrl]);

  return (
    <div className="video-container" id={id} data-id={id} data-vanity-url={vanityUrl}>
      {src && (
        <div className="video-shorts">
          <div onClick={handleVideoPress} style={{height:"100%"}}>
          <ReactPlayer
            ref={playerRef}
            url={src}
            playing={isVisible && playing}
            controls={false}
            muted={false}
            loop={false}
            className="react-player"
            width="100%"
            height="100%"
            onClick={handleVideoPress}
            onEnded={onVideoEnded}
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            onError={handleError} 
            // playIcon={<PlayArrowIcon />}
            // light={poster}
            />
          </div>
          <div className={`video-overlay ${showIcons ? '' : 'hidden'}`}>
            {playing && <falsePauseIcon className="pause-icon" />}
            {!playing && <PlayArrowIcon className="play-icon" />}
          </div>
          <div className="shortsContainer">
            <div className="shortsVideoSideIcons">
              <div className="shortsVideoSideIcon" onClick={handleLike}>
                <ThumbUpIcon style={{ color: likeColor }} />
                <span style={{ color: '#fff' }}>{likeCount}</span>
              </div>
              <div className="shortsVideoSideIcon" onClick={openCommentModal}>
                <InsertCommentIcon />
                <span style={{ color: "#fff" }}>{commentCount > 0 ? commentCount : comment}</span>
              </div>
              <div className="shortsVideoSideIcon" onClick={openShareModal}>
                <ShareIcon />
              </div>
            </div>
            <div className="shortsBottom">
              <div className="shortsDesc">
                <p>{title}</p>
              </div>
              <div className="shortDetails">
                <p style={{ whiteSpace: isExpanded ? 'normal' : 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {desc}
                </p>
                {desc?.length > 100 && (
                  <span className="show-more-button" onClick={toggleDescription}>
                    {isExpanded ? 'Show Less' : 'Show More'}
                  </span>
                )}
              </div>
            </div>
          </div>
          {isCommentModalOpen && (
            <div className="comment-modal">
              <ShortComment
                videoId={id}
                projectInfo={projectInfo}
                isFromShorts={true}
                setCommentModalOpen={setCommentModalOpen}
                setCommentCount={setCommentCount}
              />
            </div>
          )}
          {isShareModalOpen && (
            <div className="share-modal-overlay" onClick={closeShareModal}>
              <div className="share-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="share-modal-close" onClick={closeShareModal}>×</button>
                <h3><Translate textKey={'share'}/></h3>
                <div className="share-options">
                  {shareOptions}
                </div>
                <div className="share-url-container">
                  <input type="text" value={shareUrl} readOnly className="share-url-input" />
                  <button onClick={handleCopy} className="copy-button">
                    {isCopied ? 'Copied' : 'Copy'}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReactPlayerJS;
