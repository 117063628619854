import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchSubscriptionHistory } from "./service";
import { getCardDetails, createCardUpdateCheckoutSession } from '../../Subscription/service';
import Translate from "../../../Components/MultiLanguage/Translate";
import Cards from 'react-credit-cards';
import { toast } from "react-toastify";


const BillingDetails = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [subscriptionHistory, setSubscriptionHistory] = useState({});
  const [cardDetails, setCardDetails] = useState();
  const [userSubscriptionId, setUserSubscriptionId] = useState();
  const [expiryDate, setExpiryDate] = useState("");
  const navigate = useNavigate();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(() => {
    window.scroll(0, 0)
    getSubscriptionHistory();
  }, []);

  useEffect(() => {
    userSubscriptionId && fetchCardDetails();
  }, [userSubscriptionId]);

  const fetchCardDetails = async () => {

    const subscriptionResponse = await getCardDetails(appInfo, userSubscriptionId, true);

    if (subscriptionResponse?.status == 200) {
      setCardDetails(subscriptionResponse?.data?.data)
    }

  }

  const changeCard = async () => {
    const sessionResponse = await createCardUpdateCheckoutSession(appInfo, userSubscriptionId);

    if (sessionResponse?.status == 200) {
      window.location.href = sessionResponse?.data?.url;

    } else {
      toast.error(<Translate textKey={'try_again_msg'}/>);

    }
  }

  const getSubscriptionHistory = async () => {
    try {
      const response = await fetchSubscriptionHistory(appInfo);
      if (response?.status === 200) {
        setSubscriptionHistory(response?.data);
        setUserSubscriptionId(response?.data?.last_purchase?.user_subscription_id)
        const date = new Date(response?.data?.last_purchase?.valid_to);
        const nextday = new Date(response?.data?.last_purchase?.valid_to);
        nextday.setDate(date.getDate() + 1);
        setExpiryDate(nextday.toDateString());
      }
    } catch (err) { }
  };
  return (
    <div className="billingAndSubscription">
      <div className="billingDetails">
        <h1 className="heading"> <Translate textKey={'billing_details_title'} /> </h1>
        <h6 className="subHeading"> <Translate textKey={'your_membership'} /></h6>
        <div className="billingDetailsContainer">
          <div>
            <span className="head"><Translate textKey={'your_plan'} /></span>
            <span className="value bold">
              {subscriptionHistory?.last_purchase?.plan}
            </span>

            <span className="head"><Translate textKey={'your_next_bill'} /></span>
            <span className="value">{expiryDate}</span>
          </div>
          {cardDetails && <div className="active-card-details">
            <div className="left">
              <Cards
                expiry={cardDetails?.exp_month + cardDetails?.exp_year}
                focused={'number'}
                name={cardDetails?.cardholder_name}
                number={'**** **** **** ' + cardDetails?.last4}
                preview={true}
                issuer={cardDetails?.brand}
              />
            </div>

            <div className="right">
              <div className="dynamic-styles" onClick={() => { changeCard() }}><Translate textKey={'change_payment_method'}/></div>

            </div>
          </div>
          }
        </div>

      </div>

      <div className="subscriptionDetails">
        <h1 className="heading"><Translate textKey={'subscription_details_title'} /></h1>
        <ul className="subscriptionContainer">
          <li className="head">
            <div className="left">
              <div className="date"><Translate textKey={'date'} /></div>
              <div className="transactionType"><Translate textKey={'transaction_type'} /></div>
              <div className="servicePeriod"><Translate textKey={'service_period'} /></div>
              <div className="paymentMethod"><Translate textKey={'payment_method'} /></div>
              <div className="subscriptionName"><Translate textKey={'subscription_name'} /></div>
            </div>
            <div className="right">
              <div className="total"><Translate textKey={'total'} /></div>
            </div>
          </li>
          {subscriptionHistory?.purchase_history?.map((item, index) => (
            <li key={index} className="body">
              <div className="left">
                <div className="date">{item?.date}</div>
                <div className="transactionType">
                  {item?.transaction_type}
                </div>
                <div className="servicePeriod">{item?.service_period}</div>
                <div className="paymentMethod">{item?.mode_of_payment}</div>
                <div className="subscriptionName">
                  {item?.subscription_name}
                </div>
              </div>
              <div className="right">
                <div className="total">{item?.total}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="bottom">
        <p><Translate textKey={'note_billing_msg'} /></p>
        <div className="buttonContainer" onClick={() => navigate("/settings")}>
          <button ><Translate textKey={'back'} /></button>
        </div>
      </div>
    </div>
  );
};

export default BillingDetails;
